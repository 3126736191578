import { WorkShiftType } from "@dwo/shared/dist/models/workShiftModel";
import { Box } from "@material-ui/core/";
import React from "react";

interface TableDisplayHoursTypeLabelProps {
  hoursType: string;
}

export function TableDisplayHoursTypeLabel({
  hoursType,
}: TableDisplayHoursTypeLabelProps) {
  const getTypeLabel = () => {
    switch (hoursType) {
      case WorkShiftType.NORMAL:
        return "Current";
      case WorkShiftType.RAINDAY:
        return "Rain Day";
      case WorkShiftType.PTO:
        return "PTO";
      case WorkShiftType.HOLIDAY:
        return "Holiday";
      case WorkShiftType.SICK_LEAVE:
        return "Sick Leave";
      case WorkShiftType.TRAINING:
        return "Training";
      case WorkShiftType.OVERTIME:
        return "Overtime";
      case WorkShiftType.NO_SHOW:
        return "No Show";
      default:
        return "-";
    }
  };
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent={getTypeLabel() === "-" ? "center" : ""}
    >
      {getTypeLabel()}
    </Box>
  );
}
