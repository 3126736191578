import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Grid,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { DownloadJobs } from "components/upload/DownloadJobs";
import { DownloadTimesheets } from "components/upload/DownloadTimesheets";
import { DownloadVistaTimesheets } from "components/upload/DownloadVistaTimesheets";
import { Layout } from "components/layout/Layout";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { UploadDownloadTabs } from "utils/uploadUtils";
import { UploadScreen } from "./UploadScreen";
import { DownloadEmployeeCSV } from "./DownloadEmployeeCSV";
import AdminPanel from "./AdminPanel";

const tabStyles = makeStyles((theme: Theme) =>
  createStyles({
    shadowLeft: {
      backgroundImage:
        "linear-gradient(270deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    shadowRight: {
      backgroundImage:
        "linear-gradient(90deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    activeTab: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%)",
      bottom: "8px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      color: theme.palette.primary.dark,
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    disabledTab: {
      backgroundColor: "transparent",
      color: "#F0EFEF",
      textTransform: "capitalize",
    },
    tabsContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
      marginLeft: "24px",
      width: "max-content",
      height: "40px",
      [theme.breakpoints.down("xs")]: {
        width: "271px",
      },
      zIndex: 1,
    },
    indicator: {
      display: "none",
      outline: "none",
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

export function UploadDownloadManagementScreen() {
  const classes = useStyles();
  const tabClasses = tabStyles();
  const loggedInUser = useSelector(selectCurrentUser);
  const [activeTab, setActiveTab] = useState(
    loggedInUser?.employee.role === "admin"
      ? UploadDownloadTabs.UploadEmployees
      : UploadDownloadTabs.DownloadTimesheets,
  );

  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabPanel-${index}`,
    };
  };

  // ! Old Tab Shadows logic
  // const getGradients = (index: number) => {
  //   if (index === 0) {
  //     return tabClasses.shadowLeft;
  //   }
  //   return tabClasses.shadowRight;
  // };

  const handleChangeTabs = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Layout>
      <Box padding="32px 24px 24px 24px">
        <Typography className={classes.header} component="h1">
          Data Management
        </Typography>
      </Box>
      <Grid alignItems="center" container spacing={0}>
        <Grid item xs={4}>
          <Box height="32px">
            <AppBar className={tabClasses.tabsContainer} position="static">
              <Tabs
                classes={{ indicator: tabClasses.indicator }}
                value={activeTab}
                onChange={handleChangeTabs}
              >
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={
                    loggedInUser?.employee.role !== "admin"
                      ? tabClasses.disabledTab
                      : tabClasses.shadowRight
                  }
                  label="Upload Employees"
                  disabled={loggedInUser?.employee.role !== "admin"}
                  {...getA11yProps(0)}
                />
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={
                    activeTab === 2
                      ? tabClasses.shadowRight
                      : activeTab === 3
                      ? tabClasses.shadowRight
                      : tabClasses.shadowLeft
                  }
                  label="Download Timesheets"
                  {...getA11yProps(1)}
                />
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={
                    activeTab === 2
                      ? tabClasses.shadowRight
                      : activeTab === 3
                      ? tabClasses.shadowRight
                      : tabClasses.shadowLeft
                  }
                  label="Download Vista Timesheets"
                  {...getA11yProps(1)}
                />
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={
                    activeTab === 3
                      ? tabClasses.shadowRight
                      : tabClasses.shadowLeft
                  }
                  label="Download Jobs"
                  {...getA11yProps(2)}
                />
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={
                    loggedInUser?.employee.role !== "admin"
                      ? tabClasses.disabledTab
                      : tabClasses.shadowLeft
                  }
                  label="Download Employee"
                  disabled={loggedInUser?.employee.role !== "admin"}
                  {...getA11yProps(3)}
                />
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={
                    loggedInUser?.employee.role !== "admin"
                      ? tabClasses.disabledTab
                      : tabClasses.shadowLeft
                  }
                  label="Admin Panel"
                  disabled={loggedInUser?.employee.role !== "admin"}
                  {...getA11yProps(4)}
                />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
      </Grid>
      {activeTab === UploadDownloadTabs.UploadEmployees && <UploadScreen />}
      {activeTab === UploadDownloadTabs.AdminPanel && <AdminPanel />}
      {activeTab === UploadDownloadTabs.DownloadTimesheets && (
        <DownloadTimesheets />
      )}
      {activeTab === UploadDownloadTabs.DownloadVistaTimesheets && (
        <DownloadVistaTimesheets />
      )}
      {activeTab === UploadDownloadTabs.DownLoadJobs && <DownloadJobs />}
      {activeTab === UploadDownloadTabs.DownloadEmployee && (
        <DownloadEmployeeCSV />
      )}
    </Layout>
  );
}
