import { useEffect, useState } from "react";
import { settingService, Permission } from "@dwo/shared/dist/services/settingService"; 
import { employeeService } from "@dwo/shared/dist/services/employeeService"; 
import { isArray } from "lodash";
import { setLoading } from "features/employees/employeesSlice";

interface IResult {
    isRoot: boolean;
    isLoading: boolean;
    errorMessage: string;
    checkPassword: (password: string) => Promise<boolean>;
    modifyPermission: (newVal: boolean) => Promise<boolean>;
    getStatus: () => Promise<Permission | null>;
    isActive: boolean;
    timeLeft: string;
    prevModificationUser: string | undefined;
    refreshTime: () => void;
}

function getTimeDifference(startDate: Date, endDate: Date): string {
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();

    if (diffInMilliseconds < 0) {
        return '00:00';
    }

    const totalMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  }

const useRootUsers = (): IResult => {
    const [prevModificationUser, setPrevModificationUser] = useState<string>()
    const [isActive, setIsActive] = useState(false)
    const [timeLeft, setTimeLeft] = useState("");
    const [isRoot, setIsRoot] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const checkPassword = async (password: string) => {
        try {
            const { data } = await settingService.verifyPassword(password)
            setIsLoading(true)
            setErrorMessage("")

            if (!isArray(data) && data.authorized) {
                setIsRoot(true)
                setIsLoading(false)
                return true
            } else {
                setErrorMessage("Not valid password")
                setIsLoading(false)
                return false
            }   
        } catch(error) {
            setErrorMessage(error.message)
            setIsLoading(false)
            return false
        }
    }
    const getStatus = async () => {
        try {
            setIsLoading(true)
            const { data } = await settingService.getStatus()

            const current = new Date()
            const expiresAt = new Date(String(data.expires))
            
            const timeLeft = getTimeDifference(current, expiresAt)

            setTimeLeft(timeLeft)

            try {
                const userId = data.lastUpdatedByEmployeeId
                const {data: employeeData} = await employeeService.getById(userId)
                setPrevModificationUser(employeeData.fullName)
            } catch(error) {
                console.log("Error getting user", error.message)
            }

            setIsLoading(false)
            return data
        } catch(error) {
            setIsLoading(false)
            return null
        }
    }
    const modifyPermission = async (newVal: boolean) => {
        try {
            setLoading(true)
            setPrevModificationUser("")
            await settingService.modifyStatus(newVal)
            const result = await getStatus()
            setIsActive(!!result?.isActive)
            setLoading(false)
            return !!result?.isActive
        } catch(error) {
            setIsLoading(false)
            return false
        }
    }

    useEffect(() => {
        getStatus().then(data => {
            if (data) {
                setIsActive(data.isActive)
            }
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const refreshTime = () => {
        modifyPermission(isActive)
    }

    return {
        isRoot,
        isLoading,
        isActive,
        checkPassword,
        getStatus,
        modifyPermission,
        errorMessage,
        timeLeft,
        prevModificationUser,
        refreshTime
    }
}

export default useRootUsers