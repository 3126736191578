import {
  Box,
  createStyles,
  Divider,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TempWorkshiftValues } from "utils/timesheetDetailsUtils";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { WorkShiftType } from "@dwo/shared/dist/models/workShiftModel";
import { ExpandMore } from "@material-ui/icons";

interface TempWorkshiftsFormProps {
  initialValue: TempWorkshiftValues;
  handleFormikValues: (value: TempWorkshiftValues) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpText: {
      color: theme.palette.grey["400"],
      width: "70%",
      marginTop: "10px",
      marginBottom: "7px",
    },
    inputField: {
      "& label + .MuiInput-formControl": {
        marginTop: 0,
        height: "100%",
      },
    },
    textFieldRoot: {
      height: "auto",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
      "& .MuiInputAdornment-positionStart": {
        margin: "-2px -4px 0 8px",
      },
    },
    multilineTextFieldRoot: {
      height: "100%",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
    },
    divider: {
      marginTop: "16px",
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    textField: {
      height: "39px",
      "& .MuiInputBase-root": {
        height: "39px",
      },
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    dropdown: {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "2px",
      height: "40px",
      padding: 0,
      "& .MuiSelect-select:focus": {
        backgroundColor: theme.palette.grey[300],
      },
      "& .MuiSelect-icon": {
        right: 0,
        top: "calc(50% - 16px)",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.dark,
        fontSize: "2rem",
      },
      "& .MuiFilledInput-input": {
        padding: "10px 4px",
      },
    },
    dropdownMenu: {
      backgroundColor: "#F0EFEF",
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: "#838385",
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
    root: {
      color: theme.palette.grey[600],
    },
    label: {
      color: theme.palette.primary.main,
    },
  }),
);

export function TempWorkshiftsForm({
  initialValue,
  handleFormikValues,
}: TempWorkshiftsFormProps) {
  const classes = useStyles();
  const isFirstRender = useRef<boolean>(true);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: Yup.object({
      employeeName: Yup.string().required("Employee name is required"),
      end: Yup.date()
        .typeError("Date must be a valid date")
        .required("Date is required"),
      start: Yup.date()
        .typeError("Date must be a valid date")
        .required("Date is required"),
      type: Yup.string().required("Employee name is required"),
    }),
    onSubmit: noop,
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const hasFormErrors =
      Boolean(formik.errors.employeeName) ||
      Boolean(formik.errors.end) ||
      Boolean(formik.errors.start) ||
      Boolean(formik.errors.type);

    handleFormikValues({
      ...formik.values,
      error: hasFormErrors,
    });
    // eslint-disable-next-line
  }, [formik.values, formik.errors]);

  useEffect(() => {
    if (isFirstRender.current) return;

    formik.setFieldValue("start", startDate);
    formik.setFieldTouched("start", true);

    // eslint-disable-next-line
  }, [startDate]);

  useEffect(() => {
    if (isFirstRender.current) return;

    formik.setFieldValue("end", endDate);
    formik.setFieldTouched("end", true);

    // eslint-disable-next-line
  }, [endDate]);

  const handleChangeStart = (date: MaterialUiPickersDate) => {
    setStartDate(date as Date);
  };

  const handleChangeEnd = (date: MaterialUiPickersDate) => {
    setEndDate(date as Date);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Box display="flex" flexDirection="column" marginBottom="24px">
          <TextField
            value={formik.values.employeeName}
            classes={{ root: classes.textFieldRoot }}
            fullWidth
            id="employeeName"
            label="Employee Name"
            placeholder="Name"
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.employeeName && Boolean(formik.errors.employeeName) && (
            <Typography className={classes.errorMessage} variant="body2">
              {formik.errors.employeeName}
            </Typography>
          )}
          <Box display="flex" justifyContent="space-between" marginY="16px">
            <Box>
              <div className={classes.label}>Start</div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  value={startDate}
                  className={classes.textField}
                  placeholder="__:__ _"
                  format="hh:mm a"
                  helperText=""
                  onChange={handleChangeStart}
                />
              </MuiPickersUtilsProvider>
              {formik.touched.start && Boolean(formik.errors.start) && (
                <Typography className={classes.errorMessage} variant="body2">
                  {formik.errors.start}
                </Typography>
              )}
            </Box>
            <Box>
              <div className={classes.label}>End</div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  value={endDate}
                  className={classes.textField}
                  placeholder="__:__ _"
                  format="hh:mm a"
                  helperText=""
                  onChange={handleChangeEnd}
                />
              </MuiPickersUtilsProvider>
              {formik.touched.end && Boolean(formik.errors.end) && (
                <Typography className={classes.errorMessage} variant="body2">
                  {formik.errors.end}
                </Typography>
              )}
            </Box>
          </Box>
          <div className={classes.label}>Type</div>
          <FormControl fullWidth required variant="filled">
            <Select
              id="type"
              classes={{ root: classes.root }}
              className={classes.dropdown}
              IconComponent={ExpandMore}
              labelId="type"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: undefined,
                PopoverClasses: {
                  paper: classes.dropdownMenu,
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              {...formik.getFieldProps("type")}
            >
              <MenuItem value={WorkShiftType.NORMAL}>Current</MenuItem>
              <MenuItem value={WorkShiftType.RAINDAY}>Rain Day</MenuItem>
              <MenuItem value={WorkShiftType.PTO}>PTO</MenuItem>
              <MenuItem value={WorkShiftType.HOLIDAY}>Holiday</MenuItem>
              <MenuItem value={WorkShiftType.SICK_LEAVE}>Sick Leave</MenuItem>
              <MenuItem value={WorkShiftType.TRAINING}>Training</MenuItem>
              <MenuItem value={WorkShiftType.OVERTIME}>Overtime</MenuItem>
              <MenuItem value={WorkShiftType.NO_SHOW}>No Show</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider className={classes.divider} />
    </form>
  );
}
