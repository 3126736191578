import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { TimeBreakModel } from "@dwo/shared/src/models/timeBreakModel";
import { timeBreakService } from "@dwo/shared/dist/services/timeBreakService";
import { cloneDeep } from "lodash";
import { error } from "features/error/errorSlice";

export interface WorkShiftTableCellValue {
  id: string;
  idRow: number;
  inputError?: boolean;
  inputValue: string;
  isLoading?: boolean;
}

interface editWorkShiftTableState {
  values: WorkShiftTableCellValue[];
  isTimeOffChecked: boolean;
  isTimeOffDisabled: boolean;
  isLoading: boolean;
  timebreak?: TimeBreakModel;
  timebreaks: TimeBreakModel[];
}

const initialState: editWorkShiftTableState = {
  values: [],
  isTimeOffChecked: false,
  isTimeOffDisabled: false,
  isLoading: false,
  timebreak: undefined,
  timebreaks: [],
};

export const workShiftTableValuesSlice = createSlice({
  name: "workShiftTableValues",
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<WorkShiftTableCellValue>) => {
      const foundIndex = state.values.findIndex(
        (value) =>
          value.idRow === action.payload.idRow &&
          value.id === action.payload.id,
      );
      if (foundIndex !== -1) {
        state.values[foundIndex] = action.payload;
      } else {
        state.values.push(action.payload);
      }
    },
    removeValueByIdAndType: (
      state,
      action: PayloadAction<{ id: number; type: string }>,
    ) => {
      const foundIndex = state.values.findIndex(
        (value) =>
          value.idRow === action.payload.id && value.id === action.payload.type,
      );
      if (foundIndex !== -1) {
        state.values.splice(foundIndex, 1);
      }
    },
    clearValues: (state) => {
      state.values = [];
    },
    setIsTimeOffChecked: (state, action: PayloadAction<boolean>) => {
      state.isTimeOffChecked = action.payload;
    },
    setIsTimeOffDisabled: (state, action: PayloadAction<boolean>) => {
      state.isTimeOffDisabled = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTimeBreak: (state, action: PayloadAction<TimeBreakModel>) => {
      state.timebreak = cloneDeep(action.payload);
    },
  },
});

export const createTimeBreak = (timebreak: TimeBreakModel): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoading(true));

    const { data } = await timeBreakService.create(timebreak);

    dispatch(setTimeBreak(data));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create timebreak",
          message: e.message,
        },
        () => dispatch(createTimeBreak(timebreak)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const {
  clearValues,
  removeValueByIdAndType,
  setIsLoading,
  setIsTimeOffChecked,
  setIsTimeOffDisabled,
  setTimeBreak,
  setValues,
} = workShiftTableValuesSlice.actions;

export const selectValues = (state: RootState) =>
  state.workShiftTableValues.values;
export const selectIsTimeOffChecked = (state: RootState) =>
  state.workShiftTableValues.isTimeOffChecked;
export const selectIsTimeOffDisabled = (state: RootState) =>
  state.workShiftTableValues.isTimeOffDisabled;
export const selectIsLoading = (state: RootState) =>
  state.workShiftTableValues.isLoading;

export default workShiftTableValuesSlice.reducer;
