import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "lodash/fp";
import { Box } from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { ModalTabs } from "components/globalModal/ModalTabs";
import { ModalTabsContent } from "components/globalModal/ModalTabsContent";
import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import { TableCustom } from "components/table/TableCustom";
import {
  getAllEmployeesWithImage,
  selectEmployees,
  selectLoading as selectLoadingEmployees,
} from "features/employees/employeesSlice";
import { selectLoading as selectLoadingRegions } from "features/region/regionSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { DEFAULT_QUERY_LIMIT } from "utils/sharedUtils";
import {
  crewAssignmentsColumns,
  crewAvailableColumns,
  getCrewAssignmentsRows,
  getCrewAvailableRows,
} from "utils/employeeDetailsUtils";

interface CrewAssignmentsModalProps {
  crewsData: {
    assignedWorkers: EmployeeModel[];
  };
  employeeSelectedIds: string[];
  isOpen: boolean;
  regionId?: number;
  jobId?: number;
  onToggle: VoidFunction;
  onClickDelete: (id: string) => void;
  onClickSave: VoidFunction;
  onClickCheckbox: (idRow: string, isChecked: boolean) => void;
}

export function CrewAssignmentsModal({
  crewsData,
  employeeSelectedIds,
  isOpen,
  regionId,
  jobId,
  onToggle,
  onClickDelete,
  onClickSave,
  onClickCheckbox,
}: CrewAssignmentsModalProps) {
  const dispatch = useDispatch();
  const employees = useSelector(selectEmployees);
  const isLoadingEmployees = useSelector(selectLoadingEmployees);
  const isLoadingRegions = useSelector(selectLoadingRegions);
  const [activeTab, setActiveTab] = useState(0);
  const [searchedName, setSearchedName] = useState("");
  const [searchedEmployeeId, setSearchedEmployeeId] = useState("");
  const isLoading = isLoadingEmployees || isLoadingRegions;

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getAllEmployeesWithImage(
          jobId
            ? {
                where: {
                  role: { $eq: "worker" },
                  regionId,
                },
                exclude: { jobId },
                limit: DEFAULT_QUERY_LIMIT,
              }
            : {
                where: {
                  role: { $eq: "worker" },
                  regionId,
                },
                limit: DEFAULT_QUERY_LIMIT,
              },
        ),
      );
    }
  }, [dispatch, isOpen, regionId, jobId]);

  const handleChangeSearch = useCallback(
    (employeeId: string, employeeName: string) => {
      setSearchedName(employeeName);
      setSearchedEmployeeId(employeeId);
    },
    [],
  );

  const handleChangeTabs = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => setActiveTab(newValue);

  const filterEmployeesBySearchResult = (data: EmployeeModel[]) => {
    if (searchedEmployeeId && searchedName) {
      return data.filter(({ employeeId }) => employeeId === searchedEmployeeId);
    }
    return data;
  };

  const availableEmployees = employees.filter(
    (employee) =>
      !crewsData.assignedWorkers.find((worker) => worker.id === employee.id),
  );

  return (
    <GlobalModal
      showActionButtons={activeTab === 1}
      open={isOpen}
      Filters={
        activeTab === 1 && (
          <Box width="100%" padding="24px">
            <SearchByNameOrId
              filteredEmployees={availableEmployees}
              onChange={handleChangeSearch}
            />
          </Box>
        )
      }
      Tabs={
        <ModalTabs
          activeTab={activeTab}
          firstLabel={`Assigned (${crewsData.assignedWorkers.length})`}
          secondLabel={`Available (${availableEmployees.length})`}
          onChange={handleChangeTabs}
        />
      }
      title="Assignments"
      isContinueDisabled={employeeSelectedIds.length === 0}
      onClickContinue={onClickSave}
      onClickClose={onToggle}
    >
      <ModalTabsContent
        activeTab={activeTab}
        FirstTabContent={
          <TableCustom
            columns={crewAssignmentsColumns}
            isLoading={isLoading}
            noRowsMessage="There are no assigned employees."
            rows={getCrewAssignmentsRows(
              crewsData.assignedWorkers,
              onClickDelete,
            )}
            onClickSort={noop}
          />
        }
        SecondTabContent={
          <TableCustom
            columns={crewAvailableColumns}
            isLoading={isLoading}
            noRowsMessage="There are no available employees to assign."
            rows={getCrewAvailableRows(
              filterEmployeesBySearchResult(availableEmployees),
              employeeSelectedIds,
              onClickCheckbox,
            )}
            onClickSort={noop}
          />
        }
      />
    </GlobalModal>
  );
}
