import { Container, Grid, Paper, Theme, createStyles, makeStyles, FormControlLabel, Typography, FormControl, TextField, Button, Box, Switch, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import useRootUsers from "hooks/useRootUsers";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        backgroundColor: "white",
        margin: "0 24px 24px 24px",
        padding: "24px",
        borderRadius: 0,
        position: "relative",
        zIndex: 2,
      },
      helpText: {
        fontSize: "24px",
        color: theme.palette.primary.main,
        fontWeight: 500,
        },
    }),
  );

const AdminPanel: React.FC = () => {
    const {isRoot, checkPassword, refreshTime, errorMessage, isLoading, modifyPermission, isActive, timeLeft, prevModificationUser} = useRootUsers()

    const [password, setPassword] = useState("")

    const handleCheck = () => {
        checkPassword(password)
    }
    const classes = useStyles();
    const handlePermissionStatusChange = () => {
        modifyPermission(!isActive)
    }
    return (
        <Paper className={classes.paper}>
            <Container>
                {
                    !isRoot && (
                        <Grid direction="column" spacing={4} md={8}>
                            <Typography variant="body2" className={classes.helpText}>
                                Validate identity
                            </Typography>
                            <Box display={"flex"}>
                                <FormControl fullWidth error={!!errorMessage}>
                                    <TextField helperText={errorMessage} type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Type your root password" label="Root password" />
                                </FormControl>
                                <Button disabled={isLoading} variant="contained" color="primary" onClick={handleCheck} >
                                    {isLoading ? (
                                        <CircularProgress size={25} color="inherit" />
                                    ) : "Validate"}
                                </Button>
                            </Box>
                        </Grid>
                    )
                }
                {
                    isRoot && (
                        <Grid direction="row" container>
                            <Grid direction="column" spacing={4} md={4}>
                                <Typography variant="body2" className={classes.helpText}>
                                    Supervisor settings
                                </Typography>
                                <Grid 
                                    direction="row" 
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={isActive}
                                                onChange={handlePermissionStatusChange}
                                                inputProps={{ 'aria-label': "Last week's timesheets approval" }}
                                            />
                                        }
                                        label="Last week's timesheets approval" 
                                    />
                                    {isLoading && (
                                        <Box>
                                            <CircularProgress size={15} />
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid direction="column" md={4} spacing={4}>
                                <Typography variant="body2" className={classes.helpText}>
                                    Status
                                </Typography>
                                <Grid direction="row" spacing={4}                                     
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                    <Typography variant="subtitle1">
                                        Time left: {timeLeft}
                                    </Typography>
                                    <Button onClick={refreshTime} disabled={!isActive} variant="contained" style={{
                                        marginLeft: 4,
                                        height: 20
                                    }}>
                                        Refresh
                                    </Button>
                                </Grid>
                                <Grid direction="row">
                                    <Typography variant="subtitle1">
                                        Last modification by: {prevModificationUser}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Container>
        </Paper>
    )
}

export default AdminPanel;