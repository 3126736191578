import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { DWOModel } from "@dwo/shared/dist/models/DWOModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { TempWorkshiftModel } from "@dwo/shared/dist/models/tempWorkshiftModel";
import { TempActionIcons } from "components/timesheetDetails/TempActionIcons";
import { MainTableCell, MainTableColumn, MainTableRow } from "./tableUtils";
import React from "react";
import { formatHoursMinutes } from "./dateUtils";
import { WorkShiftType } from "@dwo/shared/dist/models/workShiftModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";

export const MAX_DISPLAYED_JOBS = 2;
export const MAX_DISPLAYED_SUPERVISORS = 2;

export type TempWorkshiftValues = {
  employeeName: string;
  end: string;
  start: string;
  type: string;
  error?: boolean;
};

export const getCurrentDWOJob = (
  dwoJobs: DWOJobModel[],
  dwo?: DWOModel,
  job?: JobModel,
) => {
  if (!dwo || !job || dwoJobs.length === 0) return;

  return dwoJobs.find((dwoJob: DWOJobModel) => {
    const jobId = job.id as number;
    const dwoId = dwo.id as number;

    return dwoJob.dwoId === dwoId && dwoJob.jobId === jobId;
  });
};

export const foremanJobsModalColumns: MainTableColumn[] = [
  {
    field: "checkbox",
    headerName: "",
  },
  {
    field: "jobId",
    headerName: "Job ID",
  },
  {
    field: "address",
    headerName: "Job Address",
  },
  {
    field: "region",
    headerName: "Region",
  },
  {
    field: "type",
    headerName: "Type of Job",
  },
];

export const getForemanJobsModalTableRows = (
  jobs: JobModel[],
  selectedJobs: JobModel[],
  onClickCheckbox: (idRow: string, isChecked: boolean) => void,
) =>
  jobs.map((job: JobModel) => {
    const address = { value: job.address };
    const idRow = (job.id as number).toString();
    const jobId = { value: job.jobId as string };
    const type = { value: job.type };
    const region = (() => {
      if (!job.region) {
        return { value: "No Region" };
      }

      if (typeof job.region === "string") {
        return { value: job.region };
      }

      return { value: job.region.name };
    })();

    const innerCells = [jobId, address, region, type];
    const isCheckedRow = Boolean(
      selectedJobs.find(
        (selectedJob: JobModel) => selectedJob.id?.toString() === idRow,
      ),
    );

    return {
      idRow,
      innerCells,
      isCheckedRow,
      onClickCheckbox,
    };
  });

const getTypeLabel = (hoursType: string) => {
  switch (hoursType) {
    case WorkShiftType.NORMAL:
      return "Current";
    case WorkShiftType.RAINDAY:
      return "Rain Day";
    case WorkShiftType.PTO:
      return "PTO";
    case WorkShiftType.HOLIDAY:
      return "Holiday";
    case WorkShiftType.SICK_LEAVE:
      return "Sick Leave";
    case WorkShiftType.TRAINING:
      return "Training";
    case WorkShiftType.OVERTIME:
      return "Overtime";
    case WorkShiftType.NO_SHOW:
      return "No Show";
    default:
      return "-";
  }
};

export const tempModalColumns: MainTableColumn[] = [
  { field: "employeeName", headerName: "Employee Name" },
  { field: "start", headerName: "Start" },
  { field: "end", headerName: "End" },
  { field: "type", headerName: "Type" },
  { field: "actions", headerName: "Actions" },
];

export const getTempModalRows = (
  tempWorkshifts: TempWorkshiftModel[],
  onClickSwap: (workshift: TempWorkshiftModel) => void,
  onClickEdit: (workshift: TempWorkshiftModel) => void,
  onClickDelete: (workshift: TempWorkshiftModel) => void,
): MainTableRow[] =>
  tempWorkshifts.map((tempWorkshift: TempWorkshiftModel) => {
    const employeeName: MainTableCell = {
      value: tempWorkshift.employeeName,
    };
    const start: MainTableCell = {
      value: formatHoursMinutes(new Date(tempWorkshift.start)),
    };
    const end: MainTableCell = {
      value: formatHoursMinutes(new Date(tempWorkshift.end)),
    };
    const type: MainTableCell = {
      value: getTypeLabel(tempWorkshift.type),
    };
    const action: MainTableCell = {
      value: (
        <TempActionIcons
          tempWorkshift={tempWorkshift}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
          onClickSwap={onClickSwap}
        />
      ),
    };

    const innerCells = [employeeName, start, end, type, action];

    return {
      innerCells,
    };
  });

export const swapColumns: MainTableColumn[] = [
  {
    field: "radio",
    headerName: "",
  },
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
];

export const getSwapRows = (
  employees: EmployeeModel[],
  employeeSelectedId: string,
  checkboxHandler: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] => {
  return employees.map((employee) => {
    if (employees) {
      const srcImage = employee.employeePictures
        ? (employee.employeePictures[0].picture.url as string)
        : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];
      const isCheckedRow = employeeSelectedId === idRow;

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onChangeRadio: checkboxHandler,
      };
    }
    return {} as MainTableRow;
  });
};
